import React, { useState } from "react"
import innertext from "innertext"
import { parse } from "tldts"
import YouTube from "react-youtube"
import { Tweet } from "react-twitter-widgets"
import Spinner from "../../../../assets/images/Spinner_font_awesome.svg"

const Embed = ({ html }) => {
  const url = innertext(html)
  const result = parse(url)
  const { domainWithoutSuffix } = result
  const [loaded, setLoaded] = useState(false)
  switch (domainWithoutSuffix) {
    case "twitter":
      const tweetId = /\/(\d+)$/.exec(url)[1]
      return (
        <>
          {!loaded && (
            <div className="flex flex-col justify-center items-center h-64 md:w-3/4 bg-gray-100 rounded border border-gray-300">
              Laddar tweet ...
              <Spinner className="w-12 h-12 animate-spin	" />
            </div>
          )}
          <Tweet
            tweetId={tweetId}
            onLoad={() => {
              setLoaded(true)
            }}
          />
        </>
      )

    case "youtube":
      const ytId = /v=(.*)$/.exec(url)[1]
      return (
        <YouTube videoId={ytId} containerClassName={"aspect-w-16 aspect-h-9"} />
      )
    default:
  }
}

export default Embed
